@font-face {
    font-family: 'Apercu';
    src: url('Apercu-MediumItalic.eot');
    src: local('Apercu Medium Italic'), local('Apercu-MediumItalic'),
        url('Apercu-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Apercu-MediumItalic.woff2') format('woff2'),
        url('Apercu-MediumItalic.woff') format('woff'),
        url('Apercu-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu.eot');
    src: local('Apercu Regular'), local('Apercu'),
        url('Apercu.eot?#iefix') format('embedded-opentype'),
        url('Apercu.woff2') format('woff2'),
        url('Apercu.woff') format('woff'),
        url('Apercu.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Medium.eot');
    src: local('Apercu Medium'), local('Apercu-Medium'),
        url('Apercu-Medium.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Medium.woff2') format('woff2'),
        url('Apercu-Medium.woff') format('woff'),
        url('Apercu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Italic.eot');
    src: local('Apercu Italic'), local('Apercu-Italic'),
        url('Apercu-Italic.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Italic.woff2') format('woff2'),
        url('Apercu-Italic.woff') format('woff'),
        url('Apercu-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-LightItalic.eot');
    src: local('Apercu Light Italic'), local('Apercu-LightItalic'),
        url('Apercu-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Apercu-LightItalic.woff2') format('woff2'),
        url('Apercu-LightItalic.woff') format('woff'),
        url('Apercu-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Light.eot');
    src: local('Apercu Light'), local('Apercu-Light'),
        url('Apercu-Light.eot?#iefix') format('embedded-opentype'),
        url('Apercu-Light.woff2') format('woff2'),
        url('Apercu-Light.woff') format('woff'),
        url('Apercu-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

